import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "src/app/services/baseQuery";
export const api = createApi({
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (build) => ({
    usersControllerCreate: build.mutation<
      UsersControllerCreateApiResponse,
      UsersControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    usersControllerUpdate: build.mutation<
      UsersControllerUpdateApiResponse,
      UsersControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "PUT",
        body: queryArg.adminUserUpdateDto,
      }),
    }),
    usersControllerFind: build.query<
      UsersControllerFindApiResponse,
      UsersControllerFindApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        params: {
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          documentCount: queryArg.documentCount,
          companyId: queryArg.companyId,
          isManager: queryArg.isManager,
          tfaEnabled: queryArg.tfaEnabled,
        },
      }),
    }),
    usersControllerDelete: build.mutation<
      UsersControllerDeleteApiResponse,
      UsersControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users`,
        method: "DELETE",
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerCreateCompanyUser: build.mutation<
      UsersControllerCreateCompanyUserApiResponse,
      UsersControllerCreateCompanyUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/company/users`,
        method: "POST",
        body: queryArg.companyUserCreateDto,
      }),
    }),
    usersControllerFindCompanyUsers: build.query<
      UsersControllerFindCompanyUsersApiResponse,
      UsersControllerFindCompanyUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/company/users`,
        params: {
          username: queryArg.username,
          firstName: queryArg.firstName,
          lastName: queryArg.lastName,
          email: queryArg.email,
          documentCount: queryArg.documentCount,
          isManager: queryArg.isManager,
          tfaEnabled: queryArg.tfaEnabled,
        },
      }),
    }),
    usersControllerGetUserProfile: build.query<
      UsersControllerGetUserProfileApiResponse,
      UsersControllerGetUserProfileApiArg
    >({
      query: () => ({ url: `/api/v1/users/profile` }),
    }),
    usersControllerUpdateUserProfile: build.mutation<
      UsersControllerUpdateUserProfileApiResponse,
      UsersControllerUpdateUserProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/profile`,
        method: "PATCH",
        body: queryArg.userUpdateDto,
      }),
    }),
    usersControllerSuspend: build.mutation<
      UsersControllerSuspendApiResponse,
      UsersControllerSuspendApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/suspend`,
        method: "PATCH",
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerActivate: build.mutation<
      UsersControllerActivateApiResponse,
      UsersControllerActivateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/activate`,
        method: "POST",
        body: queryArg.usernameDto,
      }),
    }),
    usersControllerAssignRole: build.mutation<
      UsersControllerAssignRoleApiResponse,
      UsersControllerAssignRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/role`,
        method: "POST",
        body: queryArg.userRoleDto,
      }),
    }),
    usersControllerMailResetPassword: build.mutation<
      UsersControllerMailResetPasswordApiResponse,
      UsersControllerMailResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/mailResetPassword`,
        method: "POST",
        body: queryArg.mailResetPasswordDto,
      }),
    }),
    usersControllerCheckResetPasswordCred: build.query<
      UsersControllerCheckResetPasswordCredApiResponse,
      UsersControllerCheckResetPasswordCredApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/checkResetPasswordCred`,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerResetPassword: build.mutation<
      UsersControllerResetPasswordApiResponse,
      UsersControllerResetPasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/resetPassword`,
        method: "POST",
        body: queryArg.changePasswordDto,
        params: { resetCode: queryArg.resetCode, email: queryArg.email },
      }),
    }),
    usersControllerChangePassword: build.mutation<
      UsersControllerChangePasswordApiResponse,
      UsersControllerChangePasswordApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/changePassword`,
        method: "POST",
        body: queryArg.changePasswordDto,
      }),
    }),
    usersControllerGetCompanies: build.query<
      UsersControllerGetCompaniesApiResponse,
      UsersControllerGetCompaniesApiArg
    >({
      query: () => ({ url: `/api/v1/users/company` }),
    }),
    usersControllerUpdateCompanyLicense: build.mutation<
      UsersControllerUpdateCompanyLicenseApiResponse,
      UsersControllerUpdateCompanyLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/users/update-company-limit`,
        method: "PATCH",
        body: queryArg.updateCompanyLimitDto,
      }),
    }),
    usersControllerGetCompanyUsers: build.query<
      UsersControllerGetCompanyUsersApiResponse,
      UsersControllerGetCompanyUsersApiArg
    >({
      query: () => ({ url: `/api/v1/users/my-company-users` }),
    }),
    usersControllerGetNotOwnerUsers: build.query<
      UsersControllerGetNotOwnerUsersApiResponse,
      UsersControllerGetNotOwnerUsersApiArg
    >({
      query: () => ({ url: `/api/v1/users/not-owner` }),
    }),
    companiesControllerFindAll: build.query<
      CompaniesControllerFindAllApiResponse,
      CompaniesControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/companies` }),
    }),
    companiesControllerCreate: build.mutation<
      CompaniesControllerCreateApiResponse,
      CompaniesControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/companies`,
        method: "POST",
        body: queryArg.companyCreateDto,
      }),
    }),
    companiesControllerDelete: build.mutation<
      CompaniesControllerDeleteApiResponse,
      CompaniesControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/companies`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    limitAndUsageControllerGetUsage: build.query<
      LimitAndUsageControllerGetUsageApiResponse,
      LimitAndUsageControllerGetUsageApiArg
    >({
      query: () => ({ url: `/api/v1/limit-and-usage/usage` }),
    }),
    limitAndUsageControllerGetCompanyUsage: build.query<
      LimitAndUsageControllerGetCompanyUsageApiResponse,
      LimitAndUsageControllerGetCompanyUsageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/limit-and-usage/company-usage/${queryArg.id}`,
      }),
    }),
    limitAndUsageControllerGetMyUsage: build.query<
      LimitAndUsageControllerGetMyUsageApiResponse,
      LimitAndUsageControllerGetMyUsageApiArg
    >({
      query: () => ({ url: `/api/v1/limit-and-usage/my-usage` }),
    }),
    notificationControllerCreateMyCompanyNotification: build.mutation<
      NotificationControllerCreateMyCompanyNotificationApiResponse,
      NotificationControllerCreateMyCompanyNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/create/my-company`,
        method: "POST",
        body: queryArg.createCompanyNotificationDto,
      }),
    }),
    notificationControllerCreateNotification: build.mutation<
      NotificationControllerCreateNotificationApiResponse,
      NotificationControllerCreateNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/create/users`,
        method: "POST",
        body: queryArg.createNotificationDto,
      }),
    }),
    notificationControllerCreateCompanyNotification: build.mutation<
      NotificationControllerCreateCompanyNotificationApiResponse,
      NotificationControllerCreateCompanyNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/create/company/${queryArg.companyId}`,
        method: "POST",
        body: queryArg.createCompanyNotificationDto,
      }),
    }),
    notificationControllerGetAllNotification: build.query<
      NotificationControllerGetAllNotificationApiResponse,
      NotificationControllerGetAllNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/admin`,
        params: { to: queryArg.to },
      }),
    }),
    notificationControllerGetUnseenNotification: build.query<
      NotificationControllerGetUnseenNotificationApiResponse,
      NotificationControllerGetUnseenNotificationApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user-unseen` }),
    }),
    notificationControllerGetPreviewUnseenNotification: build.query<
      NotificationControllerGetPreviewUnseenNotificationApiResponse,
      NotificationControllerGetPreviewUnseenNotificationApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user-unseen-preview` }),
    }),
    notificationControllerGetUnseenNotificationCount: build.query<
      NotificationControllerGetUnseenNotificationCountApiResponse,
      NotificationControllerGetUnseenNotificationCountApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user-unseen-count` }),
    }),
    notificationControllerGetNotification: build.query<
      NotificationControllerGetNotificationApiResponse,
      NotificationControllerGetNotificationApiArg
    >({
      query: () => ({ url: `/api/v1/notification/user` }),
    }),
    notificationControllerSeenNotification: build.mutation<
      NotificationControllerSeenNotificationApiResponse,
      NotificationControllerSeenNotificationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/notification/seen`,
        method: "POST",
        body: queryArg.idsDto,
      }),
    }),
    authControllerLogin: build.mutation<
      AuthControllerLoginApiResponse,
      AuthControllerLoginApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth`,
        method: "POST",
        body: queryArg.loginDto,
      }),
    }),
    authControllerSignup: build.mutation<
      AuthControllerSignupApiResponse,
      AuthControllerSignupApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/auth/signup`,
        method: "POST",
        body: queryArg.userCreateDto,
      }),
    }),
    twoFactorAuthControllerGenerateTwoFactor: build.query<
      TwoFactorAuthControllerGenerateTwoFactorApiResponse,
      TwoFactorAuthControllerGenerateTwoFactorApiArg
    >({
      query: () => ({ url: `/api/v1/tfa/generate-tfa` }),
    }),
    twoFactorAuthControllerEnableTwoFactor: build.mutation<
      TwoFactorAuthControllerEnableTwoFactorApiResponse,
      TwoFactorAuthControllerEnableTwoFactorApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tfa/enable-tfa`,
        method: "POST",
        body: queryArg.twoFactorDto,
      }),
    }),
    rolesControllerReadPermissions: build.query<
      RolesControllerReadPermissionsApiResponse,
      RolesControllerReadPermissionsApiArg
    >({
      query: () => ({ url: `/api/v1/roles/permissions` }),
    }),
    rolesControllerCreateRole: build.mutation<
      RolesControllerCreateRoleApiResponse,
      RolesControllerCreateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "POST",
        body: queryArg.roleDto,
      }),
    }),
    rolesControllerUpdateRole: build.mutation<
      RolesControllerUpdateRoleApiResponse,
      RolesControllerUpdateRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "PUT",
        body: queryArg.updateRoleDto,
      }),
    }),
    rolesControllerFindRole: build.query<
      RolesControllerFindRoleApiResponse,
      RolesControllerFindRoleApiArg
    >({
      query: () => ({ url: `/api/v1/roles` }),
    }),
    rolesControllerDeleteRole: build.mutation<
      RolesControllerDeleteRoleApiResponse,
      RolesControllerDeleteRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/roles`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    rolesControllerReadRole: build.query<
      RolesControllerReadRoleApiResponse,
      RolesControllerReadRoleApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/roles/${queryArg.roleId}` }),
    }),
    mediaControllerUploadDocument: build.mutation<
      MediaControllerUploadDocumentApiResponse,
      MediaControllerUploadDocumentApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/media`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    mediaControllerZipFolderTasks: build.query<
      MediaControllerZipFolderTasksApiResponse,
      MediaControllerZipFolderTasksApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/media/folder/${queryArg.id}` }),
    }),
    workspacesControllerGetWorkspaceById: build.query<
      WorkspacesControllerGetWorkspaceByIdApiResponse,
      WorkspacesControllerGetWorkspaceByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces`,
        params: { id: queryArg.id },
      }),
    }),
    workspacesControllerGetMyWorkspaces: build.query<
      WorkspacesControllerGetMyWorkspacesApiResponse,
      WorkspacesControllerGetMyWorkspacesApiArg
    >({
      query: () => ({ url: `/api/v1/workspaces/workspaces` }),
    }),
    workspacesControllerCreateWorkspace: build.mutation<
      WorkspacesControllerCreateWorkspaceApiResponse,
      WorkspacesControllerCreateWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/workspace`,
        method: "POST",
        body: queryArg.createWorkspaceDto,
      }),
    }),
    workspacesControllerUpdateWorkspace: build.mutation<
      WorkspacesControllerUpdateWorkspaceApiResponse,
      WorkspacesControllerUpdateWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/workspace`,
        method: "PATCH",
        body: queryArg.updateWorkspaceDto,
      }),
    }),
    workspacesControllerDeleteWorkspace: build.mutation<
      WorkspacesControllerDeleteWorkspaceApiResponse,
      WorkspacesControllerDeleteWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/workspace`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    workspacesControllerGetCompanyWorkspaces: build.query<
      WorkspacesControllerGetCompanyWorkspacesApiResponse,
      WorkspacesControllerGetCompanyWorkspacesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/company-workspaces`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    workspacesControllerInviteUserToWorkspace: build.mutation<
      WorkspacesControllerInviteUserToWorkspaceApiResponse,
      WorkspacesControllerInviteUserToWorkspaceApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/invite-user`,
        method: "POST",
        body: queryArg.inviteUsersDto,
      }),
    }),
    workspacesControllerAssignWorkspaceRole: build.mutation<
      WorkspacesControllerAssignWorkspaceRoleApiResponse,
      WorkspacesControllerAssignWorkspaceRoleApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/assign-workspace-role`,
        method: "PATCH",
        body: queryArg.assignWorkspaceRoleDto,
      }),
    }),
    workspacesControllerDeleteWorkspaceUser: build.mutation<
      WorkspacesControllerDeleteWorkspaceUserApiResponse,
      WorkspacesControllerDeleteWorkspaceUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/delete-workspace-user`,
        method: "DELETE",
        body: queryArg.deleteWorkspaceUserDto,
      }),
    }),
    workspacesControllerFindWorkspaceUser: build.query<
      WorkspacesControllerFindWorkspaceUserApiResponse,
      WorkspacesControllerFindWorkspaceUserApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/get-workspace-user`,
        params: { workspaceId: queryArg.workspaceId },
      }),
    }),
    workspacesControllerCreateNewFolder: build.mutation<
      WorkspacesControllerCreateNewFolderApiResponse,
      WorkspacesControllerCreateNewFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/create-folder`,
        method: "POST",
        body: queryArg.createNewFolderWorkspaceDto,
      }),
    }),
    workspacesControllerAddFolderTo: build.mutation<
      WorkspacesControllerAddFolderToApiResponse,
      WorkspacesControllerAddFolderToApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/add-folder-to-folder`,
        method: "POST",
        body: queryArg.addFolderToFolderDto,
      }),
    }),
    workspacesControllerRenameFolder: build.mutation<
      WorkspacesControllerRenameFolderApiResponse,
      WorkspacesControllerRenameFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/rename-folder`,
        method: "PATCH",
        body: queryArg.renameFolderWorkspaceDto,
      }),
    }),
    workspacesControllerGetFolderById: build.query<
      WorkspacesControllerGetFolderByIdApiResponse,
      WorkspacesControllerGetFolderByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/get-folder/${queryArg.workspaceId}/${queryArg.folderId}`,
      }),
    }),
    workspacesControllerGetRootFolder: build.query<
      WorkspacesControllerGetRootFolderApiResponse,
      WorkspacesControllerGetRootFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/get-root-folder/${queryArg.id}`,
      }),
    }),
    workspacesControllerRemoveFolder: build.mutation<
      WorkspacesControllerRemoveFolderApiResponse,
      WorkspacesControllerRemoveFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/remove-folder`,
        method: "PATCH",
        body: queryArg.removeFolderWorkspaceDto,
      }),
    }),
    workspacesControllerDeleteFolder: build.mutation<
      WorkspacesControllerDeleteFolderApiResponse,
      WorkspacesControllerDeleteFolderApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/workspaces/delete-folder`,
        method: "DELETE",
        body: queryArg.removeFolderWorkspaceDto,
      }),
    }),
    tasksControllerGetTask: build.query<
      TasksControllerGetTaskApiResponse,
      TasksControllerGetTaskApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tasks/task/${queryArg.id}` }),
    }),
    tasksControllerRemove: build.mutation<
      TasksControllerRemoveApiResponse,
      TasksControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/remove`,
        method: "PATCH",
        body: queryArg.idDto,
      }),
    }),
    tasksControllerDelete: build.mutation<
      TasksControllerDeleteApiResponse,
      TasksControllerDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    tasksControllerDeleteCompanyTasks: build.mutation<
      TasksControllerDeleteCompanyTasksApiResponse,
      TasksControllerDeleteCompanyTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/delete-company-tasks`,
        method: "DELETE",
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerRemoveMany: build.mutation<
      TasksControllerRemoveManyApiResponse,
      TasksControllerRemoveManyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/remove-many`,
        method: "PATCH",
        body: queryArg.idsDto,
      }),
    }),
    tasksControllerGetCompanyTasks: build.query<
      TasksControllerGetCompanyTasksApiResponse,
      TasksControllerGetCompanyTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/company-tasks`,
        params: { companyId: queryArg.companyId },
      }),
    }),
    tasksControllerAddItem: build.mutation<
      TasksControllerAddItemApiResponse,
      TasksControllerAddItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/item`,
        method: "POST",
        body: queryArg.addItemDto,
      }),
    }),
    tasksControllerDeleteItem: build.mutation<
      TasksControllerDeleteItemApiResponse,
      TasksControllerDeleteItemApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/item`,
        method: "DELETE",
        body: queryArg.idDto,
      }),
    }),
    tasksControllerAddCustomBox: build.mutation<
      TasksControllerAddCustomBoxApiResponse,
      TasksControllerAddCustomBoxApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/custom-box`,
        method: "POST",
        body: queryArg.addCustomBoxDto,
      }),
    }),
    tasksControllerGetItems: build.query<
      TasksControllerGetItemsApiResponse,
      TasksControllerGetItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/${queryArg.taskId}/${queryArg.pageNumber}/items`,
      }),
    }),
    tasksControllerUpdateBox: build.mutation<
      TasksControllerUpdateBoxApiResponse,
      TasksControllerUpdateBoxApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/box`,
        method: "PUT",
        body: queryArg.updateBoxDto,
      }),
    }),
    tasksControllerChangeRedactState: build.mutation<
      TasksControllerChangeRedactStateApiResponse,
      TasksControllerChangeRedactStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/item-redact-state`,
        method: "PUT",
        body: queryArg.changeRedactStateDto,
      }),
    }),
    tasksControllerGetLastModifiedTasks: build.query<
      TasksControllerGetLastModifiedTasksApiResponse,
      TasksControllerGetLastModifiedTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/last-modified-tasks`,
        params: { numberOfTasks: queryArg.numberOfTasks },
      }),
    }),
    tasksControllerChangeVerify: build.mutation<
      TasksControllerChangeVerifyApiResponse,
      TasksControllerChangeVerifyApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/verify`,
        method: "PUT",
        body: queryArg.verifyDto,
      }),
    }),
    tasksControllerGenerateOutput: build.mutation<
      TasksControllerGenerateOutputApiResponse,
      TasksControllerGenerateOutputApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/generate-output`,
        method: "PUT",
        body: queryArg.idDto,
      }),
    }),
    tasksControllerGetTasksState: build.query<
      TasksControllerGetTasksStateApiResponse,
      TasksControllerGetTasksStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/tasks-state`,
        params: { ids: queryArg.ids },
      }),
    }),
    tasksControllerGetTaskOutputState: build.query<
      TasksControllerGetTaskOutputStateApiResponse,
      TasksControllerGetTaskOutputStateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/task-output-state`,
        params: { id: queryArg.id },
      }),
    }),
    tasksControllerSearch: build.query<
      TasksControllerSearchApiResponse,
      TasksControllerSearchApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/search`,
        params: { taskId: queryArg.taskId, text: queryArg.text },
      }),
    }),
    tasksControllerGetPagesItems: build.query<
      TasksControllerGetPagesItemsApiResponse,
      TasksControllerGetPagesItemsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/${queryArg.taskId}/${queryArg.start}/${queryArg.end}/items`,
      }),
    }),
    tasksControllerGetTaskCurrentPage: build.query<
      TasksControllerGetTaskCurrentPageApiResponse,
      TasksControllerGetTaskCurrentPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/currentPage/${queryArg.taskId}`,
      }),
    }),
    tasksControllerSetTaskCurrentPage: build.mutation<
      TasksControllerSetTaskCurrentPageApiResponse,
      TasksControllerSetTaskCurrentPageApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/currentPage`,
        method: "PATCH",
        body: queryArg.setTaskCurrentPageDto,
      }),
    }),
    tasksControllerCreateLegalCode: build.mutation<
      TasksControllerCreateLegalCodeApiResponse,
      TasksControllerCreateLegalCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/legal-code`,
        method: "POST",
        body: queryArg.createLegalCodeDto,
      }),
    }),
    tasksControllerRemoveLegalCode: build.mutation<
      TasksControllerRemoveLegalCodeApiResponse,
      TasksControllerRemoveLegalCodeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/legal-code`,
        method: "PATCH",
        body: queryArg.removeLegalCodeDto,
      }),
    }),
    tasksControllerGetTaskPagesUrl: build.query<
      TasksControllerGetTaskPagesUrlApiResponse,
      TasksControllerGetTaskPagesUrlApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/tasks/pages/${queryArg.taskId}` }),
    }),
    tasksControllerGetTaskOutFileUrl: build.query<
      TasksControllerGetTaskOutFileUrlApiResponse,
      TasksControllerGetTaskOutFileUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/taskOutFile/${queryArg.taskId}/url`,
      }),
    }),
    tasksControllerRedactAll: build.mutation<
      TasksControllerRedactAllApiResponse,
      TasksControllerRedactAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/redact-all`,
        method: "PATCH",
        body: queryArg.redactAllDto,
      }),
    }),
    tasksControllerUnRedactAll: build.mutation<
      TasksControllerUnRedactAllApiResponse,
      TasksControllerUnRedactAllApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/un-redact-all`,
        method: "PATCH",
        body: queryArg.redactAllDto,
      }),
    }),
    tasksInternalControllerGetPdf2ImgTask: build.mutation<
      TasksInternalControllerGetPdf2ImgTaskApiResponse,
      TasksInternalControllerGetPdf2ImgTaskApiArg
    >({
      query: () => ({ url: `/api/v1/tasks/internal/pdf2img`, method: "POST" }),
    }),
    tasksInternalControllerSetPdf2ImgTaskResult: build.mutation<
      TasksInternalControllerSetPdf2ImgTaskResultApiResponse,
      TasksInternalControllerSetPdf2ImgTaskResultApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/internal/pdf2img/result`,
        method: "POST",
        body: queryArg.pdf2ImgTaskResultDto,
      }),
    }),
    tasksInternalControllerGetGeneratingTasks: build.mutation<
      TasksInternalControllerGetGeneratingTasksApiResponse,
      TasksInternalControllerGetGeneratingTasksApiArg
    >({
      query: () => ({
        url: `/api/v1/tasks/internal/get-generating-task`,
        method: "POST",
      }),
    }),
    tasksInternalControllerSetGeneratingTasks: build.mutation<
      TasksInternalControllerSetGeneratingTasksApiResponse,
      TasksInternalControllerSetGeneratingTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/tasks/internal/set-generating-task-result`,
        method: "POST",
        body: queryArg.generatingResultDto,
      }),
    }),
    wordTaggerControllerCreate: build.mutation<
      WordTaggerControllerCreateApiResponse,
      WordTaggerControllerCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/word-tagger`,
        method: "POST",
        body: queryArg.createWordDto,
      }),
    }),
    wordTaggerControllerFindAll: build.query<
      WordTaggerControllerFindAllApiResponse,
      WordTaggerControllerFindAllApiArg
    >({
      query: () => ({ url: `/api/v1/word-tagger` }),
    }),
    wordTaggerControllerFindMyWords: build.query<
      WordTaggerControllerFindMyWordsApiResponse,
      WordTaggerControllerFindMyWordsApiArg
    >({
      query: () => ({ url: `/api/v1/word-tagger/my-words` }),
    }),
    wordTaggerControllerFindOne: build.query<
      WordTaggerControllerFindOneApiResponse,
      WordTaggerControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/word-tagger/word/${queryArg.id}` }),
    }),
    wordTaggerControllerUpdate: build.mutation<
      WordTaggerControllerUpdateApiResponse,
      WordTaggerControllerUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/word-tagger/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateWordDto,
      }),
    }),
    wordTaggerControllerRemove: build.mutation<
      WordTaggerControllerRemoveApiResponse,
      WordTaggerControllerRemoveApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/word-tagger/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    licenseControllerUploadLicense: build.mutation<
      LicenseControllerUploadLicenseApiResponse,
      LicenseControllerUploadLicenseApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/license`,
        method: "POST",
        body: queryArg.licenseDto,
      }),
    }),
    licenseControllerGetLicenseInformation: build.query<
      LicenseControllerGetLicenseInformationApiResponse,
      LicenseControllerGetLicenseInformationApiArg
    >({
      query: () => ({ url: `/api/v1/license` }),
    }),
    licenseControllerLicenseUpdate: build.mutation<
      LicenseControllerLicenseUpdateApiResponse,
      LicenseControllerLicenseUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/license`,
        method: "PUT",
        body: queryArg.updateCompanyDto,
      }),
    }),
  }),
});
export type UsersControllerCreateApiResponse = /** status 201  */ UserRo;
export type UsersControllerCreateApiArg = {
  userCreateDto: UserCreateDto;
};
export type UsersControllerUpdateApiResponse = unknown;
export type UsersControllerUpdateApiArg = {
  adminUserUpdateDto: AdminUserUpdateDto;
};
export type UsersControllerFindApiResponse = /** status 200  */ UserRo[];
export type UsersControllerFindApiArg = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  companyId?: string;
  isManager?: boolean;
  tfaEnabled?: boolean;
};
export type UsersControllerDeleteApiResponse = unknown;
export type UsersControllerDeleteApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerCreateCompanyUserApiResponse =
  /** status 201  */ UserRo;
export type UsersControllerCreateCompanyUserApiArg = {
  companyUserCreateDto: CompanyUserCreateDto;
};
export type UsersControllerFindCompanyUsersApiResponse =
  /** status 200  */ UserRo[];
export type UsersControllerFindCompanyUsersApiArg = {
  username?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  isManager?: boolean;
  tfaEnabled?: boolean;
};
export type UsersControllerGetUserProfileApiResponse =
  /** status 200  */ UserRo;
export type UsersControllerGetUserProfileApiArg = void;
export type UsersControllerUpdateUserProfileApiResponse = unknown;
export type UsersControllerUpdateUserProfileApiArg = {
  userUpdateDto: UserUpdateDto;
};
export type UsersControllerSuspendApiResponse = unknown;
export type UsersControllerSuspendApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerActivateApiResponse = unknown;
export type UsersControllerActivateApiArg = {
  usernameDto: UsernameDto;
};
export type UsersControllerAssignRoleApiResponse = unknown;
export type UsersControllerAssignRoleApiArg = {
  userRoleDto: UserRoleDto;
};
export type UsersControllerMailResetPasswordApiResponse = unknown;
export type UsersControllerMailResetPasswordApiArg = {
  mailResetPasswordDto: MailResetPasswordDto;
};
export type UsersControllerCheckResetPasswordCredApiResponse = unknown;
export type UsersControllerCheckResetPasswordCredApiArg = {
  resetCode: string;
  email: string;
};
export type UsersControllerResetPasswordApiResponse = unknown;
export type UsersControllerResetPasswordApiArg = {
  resetCode: string;
  email: string;
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerChangePasswordApiResponse = unknown;
export type UsersControllerChangePasswordApiArg = {
  changePasswordDto: ChangePasswordDto;
};
export type UsersControllerGetCompaniesApiResponse =
  /** status 200  */ CompanyRo[];
export type UsersControllerGetCompaniesApiArg = void;
export type UsersControllerUpdateCompanyLicenseApiResponse = unknown;
export type UsersControllerUpdateCompanyLicenseApiArg = {
  updateCompanyLimitDto: UpdateCompanyLimitDto;
};
export type UsersControllerGetCompanyUsersApiResponse =
  /** status 200  */ UserRo[];
export type UsersControllerGetCompanyUsersApiArg = void;
export type UsersControllerGetNotOwnerUsersApiResponse =
  /** status 200  */ UserMinimalRo[];
export type UsersControllerGetNotOwnerUsersApiArg = void;
export type CompaniesControllerFindAllApiResponse =
  /** status 200  */ CompanyRo[];
export type CompaniesControllerFindAllApiArg = void;
export type CompaniesControllerCreateApiResponse = /** status 201  */ CompanyRo;
export type CompaniesControllerCreateApiArg = {
  companyCreateDto: CompanyCreateDto;
};
export type CompaniesControllerDeleteApiResponse = unknown;
export type CompaniesControllerDeleteApiArg = {
  idDto: IdDto;
};
export type LimitAndUsageControllerGetUsageApiResponse =
  /** status 200  */ UsageRo;
export type LimitAndUsageControllerGetUsageApiArg = void;
export type LimitAndUsageControllerGetCompanyUsageApiResponse =
  /** status 200  */ UsageRo;
export type LimitAndUsageControllerGetCompanyUsageApiArg = {
  id: string;
};
export type LimitAndUsageControllerGetMyUsageApiResponse =
  /** status 200  */ UsageRo;
export type LimitAndUsageControllerGetMyUsageApiArg = void;
export type NotificationControllerCreateMyCompanyNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerCreateMyCompanyNotificationApiArg = {
  createCompanyNotificationDto: CreateCompanyNotificationDto;
};
export type NotificationControllerCreateNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerCreateNotificationApiArg = {
  createNotificationDto: CreateNotificationDto;
};
export type NotificationControllerCreateCompanyNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerCreateCompanyNotificationApiArg = {
  companyId: string;
  createCompanyNotificationDto: CreateCompanyNotificationDto;
};
export type NotificationControllerGetAllNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerGetAllNotificationApiArg = {
  to: string[];
};
export type NotificationControllerGetUnseenNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerGetUnseenNotificationApiArg = void;
export type NotificationControllerGetPreviewUnseenNotificationApiResponse =
  /** status 201  */ NotificationPreviewRo[];
export type NotificationControllerGetPreviewUnseenNotificationApiArg = void;
export type NotificationControllerGetUnseenNotificationCountApiResponse =
  /** status 201  */ NotificationCountRo;
export type NotificationControllerGetUnseenNotificationCountApiArg = void;
export type NotificationControllerGetNotificationApiResponse =
  /** status 201  */ NotificationRo[];
export type NotificationControllerGetNotificationApiArg = void;
export type NotificationControllerSeenNotificationApiResponse = unknown;
export type NotificationControllerSeenNotificationApiArg = {
  idsDto: IdsDto;
};
export type AuthControllerLoginApiResponse = /** status 200  */ LoginRo;
export type AuthControllerLoginApiArg = {
  loginDto: LoginDto;
};
export type AuthControllerSignupApiResponse = /** status 201  */ LoginRo;
export type AuthControllerSignupApiArg = {
  userCreateDto: UserCreateDto;
};
export type TwoFactorAuthControllerGenerateTwoFactorApiResponse =
  /** status 200  */ TwoFactorRo;
export type TwoFactorAuthControllerGenerateTwoFactorApiArg = void;
export type TwoFactorAuthControllerEnableTwoFactorApiResponse = unknown;
export type TwoFactorAuthControllerEnableTwoFactorApiArg = {
  twoFactorDto: TwoFactorDto;
};
export type RolesControllerReadPermissionsApiResponse =
  /** status 200  */ PermissionViewRo[];
export type RolesControllerReadPermissionsApiArg = void;
export type RolesControllerCreateRoleApiResponse = /** status 201  */ RoleRo;
export type RolesControllerCreateRoleApiArg = {
  roleDto: RoleDto;
};
export type RolesControllerUpdateRoleApiResponse = unknown;
export type RolesControllerUpdateRoleApiArg = {
  updateRoleDto: UpdateRoleDto;
};
export type RolesControllerFindRoleApiResponse = /** status 200  */ RoleRo[];
export type RolesControllerFindRoleApiArg = void;
export type RolesControllerDeleteRoleApiResponse = unknown;
export type RolesControllerDeleteRoleApiArg = {
  idDto: IdDto;
};
export type RolesControllerReadRoleApiResponse = /** status 200  */ RoleRo;
export type RolesControllerReadRoleApiArg = {
  roleId: string;
};
export type MediaControllerUploadDocumentApiResponse = /** status 200  */ IdRo;
export type MediaControllerUploadDocumentApiArg = {
  body: {
    file: Blob;
    pagesRanges: PagesRangeDto[];
    haveLegalCode: boolean;
    workspaceId: string;
    folderId?: string;
    description?: string;
    setting?: SettingDto;
  };
};
export type MediaControllerZipFolderTasksApiResponse = /** status 200  */ UrlRo;
export type MediaControllerZipFolderTasksApiArg = {
  id: string;
};
export type WorkspacesControllerGetWorkspaceByIdApiResponse =
  /** status 200  */ WorkspaceRo;
export type WorkspacesControllerGetWorkspaceByIdApiArg = {
  id: string;
};
export type WorkspacesControllerGetMyWorkspacesApiResponse =
  /** status 200  */ WorkspacePreviewRo[];
export type WorkspacesControllerGetMyWorkspacesApiArg = void;
export type WorkspacesControllerCreateWorkspaceApiResponse =
  /** status 200  */ string;
export type WorkspacesControllerCreateWorkspaceApiArg = {
  createWorkspaceDto: CreateWorkspaceDto;
};
export type WorkspacesControllerUpdateWorkspaceApiResponse =
  /** status 200  */ WorkspaceRo;
export type WorkspacesControllerUpdateWorkspaceApiArg = {
  updateWorkspaceDto: UpdateWorkspaceDto;
};
export type WorkspacesControllerDeleteWorkspaceApiResponse = unknown;
export type WorkspacesControllerDeleteWorkspaceApiArg = {
  idDto: IdDto;
};
export type WorkspacesControllerGetCompanyWorkspacesApiResponse =
  /** status 200  */ WorkspacePreviewRo[];
export type WorkspacesControllerGetCompanyWorkspacesApiArg = {
  companyId?: string;
};
export type WorkspacesControllerInviteUserToWorkspaceApiResponse =
  /** status 200  */ InviteUserRo[];
export type WorkspacesControllerInviteUserToWorkspaceApiArg = {
  inviteUsersDto: InviteUsersDto;
};
export type WorkspacesControllerAssignWorkspaceRoleApiResponse = unknown;
export type WorkspacesControllerAssignWorkspaceRoleApiArg = {
  assignWorkspaceRoleDto: AssignWorkspaceRoleDto;
};
export type WorkspacesControllerDeleteWorkspaceUserApiResponse = unknown;
export type WorkspacesControllerDeleteWorkspaceUserApiArg = {
  deleteWorkspaceUserDto: DeleteWorkspaceUserDto;
};
export type WorkspacesControllerFindWorkspaceUserApiResponse =
  /** status 200  */ WorkspaceUserRo;
export type WorkspacesControllerFindWorkspaceUserApiArg = {
  workspaceId: string;
};
export type WorkspacesControllerCreateNewFolderApiResponse =
  /** status 200  */ IdRo;
export type WorkspacesControllerCreateNewFolderApiArg = {
  createNewFolderWorkspaceDto: CreateNewFolderWorkspaceDto;
};
export type WorkspacesControllerAddFolderToApiResponse =
  /** status 200  */ IdRo;
export type WorkspacesControllerAddFolderToApiArg = {
  addFolderToFolderDto: AddFolderToFolderDto;
};
export type WorkspacesControllerRenameFolderApiResponse = unknown;
export type WorkspacesControllerRenameFolderApiArg = {
  renameFolderWorkspaceDto: RenameFolderWorkspaceDto;
};
export type WorkspacesControllerGetFolderByIdApiResponse =
  /** status 200  */ FolderRo;
export type WorkspacesControllerGetFolderByIdApiArg = {
  folderId: string;
  workspaceId: string;
};
export type WorkspacesControllerGetRootFolderApiResponse =
  /** status 200  */ FolderRo;
export type WorkspacesControllerGetRootFolderApiArg = {
  id: string;
};
export type WorkspacesControllerRemoveFolderApiResponse = unknown;
export type WorkspacesControllerRemoveFolderApiArg = {
  removeFolderWorkspaceDto: RemoveFolderWorkspaceDto;
};
export type WorkspacesControllerDeleteFolderApiResponse = unknown;
export type WorkspacesControllerDeleteFolderApiArg = {
  removeFolderWorkspaceDto: RemoveFolderWorkspaceDto;
};
export type TasksControllerGetTaskApiResponse = /** status 200  */ TaskRo;
export type TasksControllerGetTaskApiArg = {
  id: string;
};
export type TasksControllerRemoveApiResponse = unknown;
export type TasksControllerRemoveApiArg = {
  idDto: IdDto;
};
export type TasksControllerDeleteApiResponse = unknown;
export type TasksControllerDeleteApiArg = {
  idDto: IdDto;
};
export type TasksControllerDeleteCompanyTasksApiResponse = unknown;
export type TasksControllerDeleteCompanyTasksApiArg = {
  id: string;
};
export type TasksControllerRemoveManyApiResponse = unknown;
export type TasksControllerRemoveManyApiArg = {
  idsDto: IdsDto;
};
export type TasksControllerGetCompanyTasksApiResponse =
  /** status 200  */ TaskRo[];
export type TasksControllerGetCompanyTasksApiArg = {
  companyId?: string;
};
export type TasksControllerAddItemApiResponse = /** status 200  */ ItemRo;
export type TasksControllerAddItemApiArg = {
  addItemDto: AddItemDto;
};
export type TasksControllerDeleteItemApiResponse = /** status 200  */ IdDto;
export type TasksControllerDeleteItemApiArg = {
  idDto: IdDto;
};
export type TasksControllerAddCustomBoxApiResponse = /** status 200  */ ItemRo;
export type TasksControllerAddCustomBoxApiArg = {
  addCustomBoxDto: AddCustomBoxDto;
};
export type TasksControllerGetItemsApiResponse = /** status 200  */ ItemsRo;
export type TasksControllerGetItemsApiArg = {
  taskId: string;
  pageNumber: number;
};
export type TasksControllerUpdateBoxApiResponse = unknown;
export type TasksControllerUpdateBoxApiArg = {
  updateBoxDto: UpdateBoxDto;
};
export type TasksControllerChangeRedactStateApiResponse = unknown;
export type TasksControllerChangeRedactStateApiArg = {
  changeRedactStateDto: ChangeRedactStateDto;
};
export type TasksControllerGetLastModifiedTasksApiResponse =
  /** status 200  */ TaskRo[];
export type TasksControllerGetLastModifiedTasksApiArg = {
  numberOfTasks: number;
};
export type TasksControllerChangeVerifyApiResponse = unknown;
export type TasksControllerChangeVerifyApiArg = {
  verifyDto: VerifyDto;
};
export type TasksControllerGenerateOutputApiResponse = unknown;
export type TasksControllerGenerateOutputApiArg = {
  idDto: IdDto;
};
export type TasksControllerGetTasksStateApiResponse =
  /** status 200  */ TasksStateRo;
export type TasksControllerGetTasksStateApiArg = {
  ids: string[];
};
export type TasksControllerGetTaskOutputStateApiResponse =
  /** status 200  */ TaskOutputStateRo;
export type TasksControllerGetTaskOutputStateApiArg = {
  id: string;
};
export type TasksControllerSearchApiResponse = /** status 200  */ ItemsRo[];
export type TasksControllerSearchApiArg = {
  taskId: string;
  text: string;
};
export type TasksControllerGetPagesItemsApiResponse =
  /** status 200  */ ItemsRo[];
export type TasksControllerGetPagesItemsApiArg = {
  taskId: string;
  start: number;
  end: number;
};
export type TasksControllerGetTaskCurrentPageApiResponse =
  /** status 200  */ CurrentPageRo;
export type TasksControllerGetTaskCurrentPageApiArg = {
  taskId: string;
};
export type TasksControllerSetTaskCurrentPageApiResponse = unknown;
export type TasksControllerSetTaskCurrentPageApiArg = {
  setTaskCurrentPageDto: SetTaskCurrentPageDto;
};
export type TasksControllerCreateLegalCodeApiResponse = unknown;
export type TasksControllerCreateLegalCodeApiArg = {
  createLegalCodeDto: CreateLegalCodeDto;
};
export type TasksControllerRemoveLegalCodeApiResponse = unknown;
export type TasksControllerRemoveLegalCodeApiArg = {
  removeLegalCodeDto: RemoveLegalCodeDto;
};
export type TasksControllerGetTaskPagesUrlApiResponse =
  /** status 200  */ PageRo[];
export type TasksControllerGetTaskPagesUrlApiArg = {
  taskId: string;
};
export type TasksControllerGetTaskOutFileUrlApiResponse =
  /** status 200  */ UrlRo;
export type TasksControllerGetTaskOutFileUrlApiArg = {
  taskId: string;
};
export type TasksControllerRedactAllApiResponse = unknown;
export type TasksControllerRedactAllApiArg = {
  redactAllDto: RedactAllDto;
};
export type TasksControllerUnRedactAllApiResponse = unknown;
export type TasksControllerUnRedactAllApiArg = {
  redactAllDto: RedactAllDto;
};
export type TasksInternalControllerGetPdf2ImgTaskApiResponse =
  /** status 200  */ Pdf2ImgTaskRo;
export type TasksInternalControllerGetPdf2ImgTaskApiArg = void;
export type TasksInternalControllerSetPdf2ImgTaskResultApiResponse = unknown;
export type TasksInternalControllerSetPdf2ImgTaskResultApiArg = {
  pdf2ImgTaskResultDto: Pdf2ImgTaskResultDto;
};
export type TasksInternalControllerGetGeneratingTasksApiResponse =
  /** status 200  */ PdfGeneratingTaskRo;
export type TasksInternalControllerGetGeneratingTasksApiArg = void;
export type TasksInternalControllerSetGeneratingTasksApiResponse = unknown;
export type TasksInternalControllerSetGeneratingTasksApiArg = {
  generatingResultDto: GeneratingResultDto;
};
export type WordTaggerControllerCreateApiResponse = /** status 200  */ WordRo;
export type WordTaggerControllerCreateApiArg = {
  createWordDto: CreateWordDto;
};
export type WordTaggerControllerFindAllApiResponse =
  /** status 200  */ WordRo[];
export type WordTaggerControllerFindAllApiArg = void;
export type WordTaggerControllerFindMyWordsApiResponse =
  /** status 200  */ WordRo[];
export type WordTaggerControllerFindMyWordsApiArg = void;
export type WordTaggerControllerFindOneApiResponse = /** status 200  */ WordRo;
export type WordTaggerControllerFindOneApiArg = {
  id: string;
};
export type WordTaggerControllerUpdateApiResponse = unknown;
export type WordTaggerControllerUpdateApiArg = {
  id: string;
  updateWordDto: UpdateWordDto;
};
export type WordTaggerControllerRemoveApiResponse = unknown;
export type WordTaggerControllerRemoveApiArg = {
  id: string;
};
export type LicenseControllerUploadLicenseApiResponse =
  /** status 201  */ LicenseRo;
export type LicenseControllerUploadLicenseApiArg = {
  licenseDto: LicenseDto;
};
export type LicenseControllerGetLicenseInformationApiResponse =
  /** status 201  */ LicenseRo;
export type LicenseControllerGetLicenseInformationApiArg = void;
export type LicenseControllerLicenseUpdateApiResponse =
  /** status 201  */ string;
export type LicenseControllerLicenseUpdateApiArg = {
  updateCompanyDto: UpdateCompanyDto;
};
export type CompanyUsageRo = {
  maxUsers: number;
  maxPages: number;
  expirationTime: string;
};
export type CompanyRo = {
  id: string;
  name: string;
  owner: string;
  limit: CompanyUsageRo;
};
export type UserRo = {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  isActive: boolean;
  documentCount: number;
  company?: CompanyRo;
  isManager?: boolean;
  lastLogin: string;
  tfaEnabled?: boolean;
};
export type UserCreateDto = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  companyName?: string;
  isManager: boolean;
};
export type AdminUserUpdateDto = {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  documentCount?: number;
  companyName?: string;
  isManager?: boolean;
};
export type UsernameDto = {
  username: string;
};
export type CompanyUserCreateDto = {
  username: string;
  password: string;
  firstName: string;
  lastName: string;
  email: string;
  isManager?: boolean;
};
export type UserUpdateDto = {
  username: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  tfaEnabled?: boolean;
};
export type UserRoleDto = {
  username: string;
  roleIds: string[];
};
export type MailResetPasswordDto = {
  email: string;
  callbackUrl: string;
};
export type ChangePasswordDto = {
  password: string;
};
export type UpdateCompanyLimitDto = {
  id: string;
  maxUsers: number;
  maxPages: number;
  expirationTime: string;
};
export type UserMinimalRo = {
  username: string;
  firstName: string;
  lastName: string;
};
export type CompanyCreateDto = {
  companyName: string;
  owner: string;
};
export type IdDto = {
  id: string;
};
export type UsageRo = {
  taskCount: number;
  pageCount: number;
  workspaceCount: number;
};
export type NotificationRo = {
  to: string;
  from: string;
  title: string;
  text: string;
  date: string;
  id: string;
};
export type CreateCompanyNotificationDto = {
  title: string;
  text: string;
};
export type CreateNotificationDto = {
  to: string[];
  title: string;
  text: string;
};
export type NotificationPreviewRo = {
  to: string;
  from: string;
  title: string;
  text: string;
  date: string;
  id: string;
};
export type NotificationCountRo = {
  notificationCount: number;
};
export type IdsDto = {
  ids: string[];
};
export type UserLoginRo = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  roleNames: string[];
  isActive: boolean;
  documentCount: number;
  isManager?: boolean;
  tfaEnabled?: boolean;
};
export type LoginRo = {
  token: string;
  user: UserLoginRo;
};
export type LoginDto = {
  username: string;
  password: string;
  twoFactorToken: string;
};
export type TwoFactorRo = {
  otpauthUrl: string;
  secret: string;
  qrCode: string;
};
export type TwoFactorDto = {
  tfaSecret: string;
  token: string;
};
export type PermissionViewRo = {
  id: string;
  category: string;
  group: string;
  name: string;
};
export type RoleRo = {
  id: string;
  name: string;
  permissions: "ADMIN" | "PDF2IMG" | "GET_AI_TASKS" | "GET_GENERATING_TASKS";
};
export type RoleDto = {
  name: string;
  permissions: (
    | "ADMIN"
    | "PDF2IMG"
    | "GET_AI_TASKS"
    | "GET_GENERATING_TASKS"
  )[];
};
export type UpdateRoleDto = {
  id: string;
  name: string;
  permissions: (
    | "ADMIN"
    | "PDF2IMG"
    | "GET_AI_TASKS"
    | "GET_GENERATING_TASKS"
  )[];
};
export type IdRo = {
  id: string;
};
export type PagesRangeDto = {
  from: number;
  to: number;
};
export type SettingDto = {
  language: "EN" | "NL" | "FR" | "IT" | "DE" | "ES" | "PT" | "ZHS" | "ZHT";
  autoRedactTags: (
    | "PERSON"
    | "LOCATION"
    | "ORGANIZATION"
    | "PHONE"
    | "EMAIL"
    | "DATE"
    | "NAME"
    | "LINK"
  )[];
  setLegalCode: boolean;
};
export type UrlRo = {
  url: string;
};
export type SettingRo = {
  language: "EN" | "NL" | "FR" | "IT" | "DE" | "ES" | "PT" | "ZHS" | "ZHT";
  autoRedactTags: (
    | "PERSON"
    | "LOCATION"
    | "ORGANIZATION"
    | "PHONE"
    | "EMAIL"
    | "DATE"
    | "NAME"
    | "LINK"
  )[];
  setLegalCode: boolean;
};
export type WorkspaceRo = {
  id: string;
  name: string;
  creator: string;
  companyId: string;
  workspaceUsers: string[];
  workspaceMaintainers: string[];
  folderId: string;
  description: string;
  defaultSetting: SettingRo;
  createdAt: string;
};
export type WorkspacePreviewRo = {
  id: string;
  name: string;
  creator: string;
  usersCount: number;
  foldersCount: number;
  tasksCount: number;
  description: string;
  state:
    | "CREATE_IMAGES"
    | "CREATING_IMAGES"
    | "PENDING"
    | "PREPARING"
    | "PARTIAL_READY"
    | "READY"
    | "ERROR";
  createdAt: string;
};
export type CreateWorkspaceDto = {
  name: string;
  workspaceUsers: string[];
  isCompanyLevel: boolean;
  description?: string;
  defaultSetting: SettingDto;
};
export type UpdateWorkspaceDto = {
  id: string;
  name: string;
  description?: string;
  defaultSetting: SettingDto;
};
export type InviteUserRo = {
  username: string;
  access: ("WORKSPACE_MANAGER" | "WORKSPACE_USER")[];
};
export type InviteUsersDto = {
  workspaceId: string;
  workspaceUsers: string[];
};
export type AssignWorkspaceRoleDto = {
  workspaceId: string;
  username: string;
  access: "WORKSPACE_MANAGER" | "WORKSPACE_USER";
};
export type DeleteWorkspaceUserDto = {
  workspaceId: string;
  username: string;
};
export type WorkspaceUserRo = {
  usernames: any[];
};
export type CreateNewFolderWorkspaceDto = {
  folderName: string;
  workspaceId: string;
};
export type AddFolderToFolderDto = {
  folderName: string;
  rootFolderId: string;
  workspaceId: string;
};
export type RenameFolderWorkspaceDto = {
  folderName: string;
  workspaceId: string;
  folderId: string;
};
export type PreviewFolderRo = {
  id: string;
  name: string;
};
export type TaskWorkspaceRo = {
  id: string;
  name: string;
};
export type TaskRo = {
  id: string;
  creator: string;
  name: string;
  numberOfPages: number;
  state:
    | "CREATE_IMAGES"
    | "CREATING_IMAGES"
    | "PENDING"
    | "PREPARING"
    | "PARTIAL_READY"
    | "READY"
    | "ERROR";
  outputState:
    | "NOT_GENERATED"
    | "GENERATE_OUTPUT"
    | "GENERATING_OUTPUT"
    | "GENERATED_OUTPUT"
    | "ERROR";
  createdAt: string;
  isDeleted?: boolean;
  description: string;
  workspace?: TaskWorkspaceRo;
  setting: SettingRo;
};
export type FolderRo = {
  id: string;
  name: string;
  folders: PreviewFolderRo[];
  tasks: TaskRo[];
};
export type RemoveFolderWorkspaceDto = {
  workspaceId: string;
  folderId: string;
};
export type BoxRo = {
  id: string;
  text?: string;
  rect: any[];
};
export type ItemRo = {
  id: string;
  text?: string;
  boxes: BoxRo[];
  accuracy: number;
  redact: boolean;
  custom: boolean;
  tag?:
    | "PERSON"
    | "LOCATION"
    | "ORGANIZATION"
    | "PHONE"
    | "EMAIL"
    | "DATE"
    | "NAME"
    | "LINK";
  legalCode:
    | "LEGAL_0"
    | "NONE"
    | "L101A"
    | "L101B"
    | "L101C"
    | "L101D"
    | "L102A"
    | "L102B"
    | "L102C"
    | "L102D"
    | "L102E"
    | "L102F"
    | "L102G"
    | "L105"
    | "L107A"
    | "L107B"
    | "L111"
    | "L112"
    | "L114"
    | "L511A"
    | "L511B"
    | "L511C"
    | "L511D"
    | "L511E"
    | "L512A"
    | "L512B"
    | "L512C"
    | "L512D"
    | "L512E"
    | "L512F"
    | "L512G"
    | "L512H"
    | "L512I"
    | "L514"
    | "L515"
    | "L521"
    | "L522"
    | "L523"
    | "L53";
};
export type AddItemDto = {
  taskId: string;
  pageNumber: number;
  boxesId: string[];
};
export type AddCustomBoxDto = {
  taskId: string;
  pageNumber: number;
  rect: any[];
};
export type ItemsRo = {
  items: ItemRo[];
};
export type UpdateBoxDto = {
  id: string;
  rect: any[];
};
export type ChangeRedactStateDto = {
  itemId: string;
  isRedact: boolean;
};
export type VerifyDto = {
  id: string;
  isVerify: boolean;
};
export type TasksStateRo = {
  tasksState: (
    | "CREATE_IMAGES"
    | "CREATING_IMAGES"
    | "PENDING"
    | "PREPARING"
    | "PARTIAL_READY"
    | "READY"
    | "ERROR"
  )[];
};
export type TaskOutputStateRo = {
  outputState:
    | "NOT_GENERATED"
    | "GENERATE_OUTPUT"
    | "GENERATING_OUTPUT"
    | "GENERATED_OUTPUT"
    | "ERROR";
};
export type CurrentPageRo = {
  index: number;
};
export type SetTaskCurrentPageDto = {
  id: string;
  index: number;
};
export type CreateLegalCodeDto = {
  legalCode:
    | "LEGAL_0"
    | "NONE"
    | "L101A"
    | "L101B"
    | "L101C"
    | "L101D"
    | "L102A"
    | "L102B"
    | "L102C"
    | "L102D"
    | "L102E"
    | "L102F"
    | "L102G"
    | "L105"
    | "L107A"
    | "L107B"
    | "L111"
    | "L112"
    | "L114"
    | "L511A"
    | "L511B"
    | "L511C"
    | "L511D"
    | "L511E"
    | "L512A"
    | "L512B"
    | "L512C"
    | "L512D"
    | "L512E"
    | "L512F"
    | "L512G"
    | "L512H"
    | "L512I"
    | "L514"
    | "L515"
    | "L521"
    | "L522"
    | "L523"
    | "L53";
  itemId: string;
  taskId: string;
};
export type RemoveLegalCodeDto = {
  itemId: string;
  taskId: string;
};
export type PageRo = {
  imagePath: string;
  aspectRatio: number;
};
export type RedactAllDto = {
  taskId: string;
  word: string;
};
export type Pdf2ImgTaskRo = {
  id: string;
  url: string;
  folderName: string;
  pagesRanges: string[];
};
export type Pdf2ImgPageDto = {
  imagePath: string;
  aspectRatio: number;
};
export type Pdf2ImgTaskResultDto = {
  id: string;
  pages: Pdf2ImgPageDto[];
};
export type GeneratingBoxRo = {
  rect: any[];
};
export type GeneratingItemRo = {
  boxes: GeneratingBoxRo[];
  redact: boolean;
  legalCode:
    | "LEGAL_0"
    | "NONE"
    | "L101A"
    | "L101B"
    | "L101C"
    | "L101D"
    | "L102A"
    | "L102B"
    | "L102C"
    | "L102D"
    | "L102E"
    | "L102F"
    | "L102G"
    | "L105"
    | "L107A"
    | "L107B"
    | "L111"
    | "L112"
    | "L114"
    | "L511A"
    | "L511B"
    | "L511C"
    | "L511D"
    | "L511E"
    | "L512A"
    | "L512B"
    | "L512C"
    | "L512D"
    | "L512E"
    | "L512F"
    | "L512G"
    | "L512H"
    | "L512I"
    | "L514"
    | "L515"
    | "L521"
    | "L522"
    | "L523"
    | "L53";
};
export type GeneratingPageRo = {
  items: GeneratingItemRo[];
  imagePath: string;
};
export type PdfGeneratingTaskRo = {
  id: string;
  name: string;
  pages: GeneratingPageRo[];
};
export type GeneratingResultDto = {
  id: string;
  outFilePath: string;
};
export type ScopeObjectRo = {
  id: string;
  name: string;
};
export type WordRo = {
  id: string;
  creator: string;
  word: string;
  tag?: "PERSON" | "LOCATION" | "ORGANIZATION" | "PHONE" | "EMAIL" | "DATE";
  workspaces: ScopeObjectRo[];
  companies: ScopeObjectRo[];
  isGlobal: boolean;
  language: "EN" | "NL" | "FR" | "IT" | "DE" | "ES" | "PT" | "ZHS" | "ZHT";
};
export type CreateWordDto = {
  word: string;
  tag?: "PERSON" | "LOCATION" | "ORGANIZATION" | "PHONE" | "EMAIL" | "DATE";
  language: string;
  workspaceIds: string[];
  companyIds: string[];
  isGlobal: boolean;
};
export type UpdateWordDto = {
  word: string;
  tag: "PERSON" | "LOCATION" | "ORGANIZATION" | "PHONE" | "EMAIL" | "DATE";
  language: string;
  workspaceIds: string[];
  companyIds: string[];
};
export type LicenseDetailRo = {
  serverId: string;
  licenseId: string;
  product: string;
  licensePlan: "NORMAL" | "PRO" | "ULTIMATE" | "TRIAL";
  maxUsers: number;
  maxPages: number;
  expirationTime: string;
  issuanceDate: string;
};
export type LicenseRo = {
  serverId: string;
  licenseDetail: LicenseDetailRo | null;
  licenseUploadDate: string | null;
};
export type LicenseDto = {
  license: string;
};
export type UpdateCompanyDto = {
  companyId: string;
  maxUsers: number;
  maxPages: number;
  expirationTime: string;
};
export const {
  useUsersControllerCreateMutation,
  useUsersControllerUpdateMutation,
  useUsersControllerFindQuery,
  useUsersControllerDeleteMutation,
  useUsersControllerCreateCompanyUserMutation,
  useUsersControllerFindCompanyUsersQuery,
  useUsersControllerGetUserProfileQuery,
  useUsersControllerUpdateUserProfileMutation,
  useUsersControllerSuspendMutation,
  useUsersControllerActivateMutation,
  useUsersControllerAssignRoleMutation,
  useUsersControllerMailResetPasswordMutation,
  useUsersControllerCheckResetPasswordCredQuery,
  useUsersControllerResetPasswordMutation,
  useUsersControllerChangePasswordMutation,
  useUsersControllerGetCompaniesQuery,
  useUsersControllerUpdateCompanyLicenseMutation,
  useUsersControllerGetCompanyUsersQuery,
  useUsersControllerGetNotOwnerUsersQuery,
  useCompaniesControllerFindAllQuery,
  useCompaniesControllerCreateMutation,
  useCompaniesControllerDeleteMutation,
  useLimitAndUsageControllerGetUsageQuery,
  useLimitAndUsageControllerGetCompanyUsageQuery,
  useLimitAndUsageControllerGetMyUsageQuery,
  useNotificationControllerCreateMyCompanyNotificationMutation,
  useNotificationControllerCreateNotificationMutation,
  useNotificationControllerCreateCompanyNotificationMutation,
  useNotificationControllerGetAllNotificationQuery,
  useNotificationControllerGetUnseenNotificationQuery,
  useNotificationControllerGetPreviewUnseenNotificationQuery,
  useNotificationControllerGetUnseenNotificationCountQuery,
  useNotificationControllerGetNotificationQuery,
  useNotificationControllerSeenNotificationMutation,
  useAuthControllerLoginMutation,
  useAuthControllerSignupMutation,
  useTwoFactorAuthControllerGenerateTwoFactorQuery,
  useTwoFactorAuthControllerEnableTwoFactorMutation,
  useRolesControllerReadPermissionsQuery,
  useRolesControllerCreateRoleMutation,
  useRolesControllerUpdateRoleMutation,
  useRolesControllerFindRoleQuery,
  useRolesControllerDeleteRoleMutation,
  useRolesControllerReadRoleQuery,
  useMediaControllerUploadDocumentMutation,
  useMediaControllerZipFolderTasksQuery,
  useWorkspacesControllerGetWorkspaceByIdQuery,
  useWorkspacesControllerGetMyWorkspacesQuery,
  useWorkspacesControllerCreateWorkspaceMutation,
  useWorkspacesControllerUpdateWorkspaceMutation,
  useWorkspacesControllerDeleteWorkspaceMutation,
  useWorkspacesControllerGetCompanyWorkspacesQuery,
  useWorkspacesControllerInviteUserToWorkspaceMutation,
  useWorkspacesControllerAssignWorkspaceRoleMutation,
  useWorkspacesControllerDeleteWorkspaceUserMutation,
  useWorkspacesControllerFindWorkspaceUserQuery,
  useWorkspacesControllerCreateNewFolderMutation,
  useWorkspacesControllerAddFolderToMutation,
  useWorkspacesControllerRenameFolderMutation,
  useWorkspacesControllerGetFolderByIdQuery,
  useWorkspacesControllerGetRootFolderQuery,
  useWorkspacesControllerRemoveFolderMutation,
  useWorkspacesControllerDeleteFolderMutation,
  useTasksControllerGetTaskQuery,
  useTasksControllerRemoveMutation,
  useTasksControllerDeleteMutation,
  useTasksControllerDeleteCompanyTasksMutation,
  useTasksControllerRemoveManyMutation,
  useTasksControllerGetCompanyTasksQuery,
  useTasksControllerAddItemMutation,
  useTasksControllerDeleteItemMutation,
  useTasksControllerAddCustomBoxMutation,
  useTasksControllerGetItemsQuery,
  useTasksControllerUpdateBoxMutation,
  useTasksControllerChangeRedactStateMutation,
  useTasksControllerGetLastModifiedTasksQuery,
  useTasksControllerChangeVerifyMutation,
  useTasksControllerGenerateOutputMutation,
  useTasksControllerGetTasksStateQuery,
  useTasksControllerGetTaskOutputStateQuery,
  useTasksControllerSearchQuery,
  useTasksControllerGetPagesItemsQuery,
  useTasksControllerGetTaskCurrentPageQuery,
  useTasksControllerSetTaskCurrentPageMutation,
  useTasksControllerCreateLegalCodeMutation,
  useTasksControllerRemoveLegalCodeMutation,
  useTasksControllerGetTaskPagesUrlQuery,
  useTasksControllerGetTaskOutFileUrlQuery,
  useTasksControllerRedactAllMutation,
  useTasksControllerUnRedactAllMutation,
  useTasksInternalControllerGetPdf2ImgTaskMutation,
  useTasksInternalControllerSetPdf2ImgTaskResultMutation,
  useTasksInternalControllerGetGeneratingTasksMutation,
  useTasksInternalControllerSetGeneratingTasksMutation,
  useWordTaggerControllerCreateMutation,
  useWordTaggerControllerFindAllQuery,
  useWordTaggerControllerFindMyWordsQuery,
  useWordTaggerControllerFindOneQuery,
  useWordTaggerControllerUpdateMutation,
  useWordTaggerControllerRemoveMutation,
  useLicenseControllerUploadLicenseMutation,
  useLicenseControllerGetLicenseInformationQuery,
  useLicenseControllerLicenseUpdateMutation,
} = api;

